// We check for undefined values within the code
/* eslint-disable react/require-default-props */
import React from 'react';
import { createSystemFeedback } from 'react-style-guide';
import { TDetailEntry, TItemDetailRequestEntry } from 'Roblox';
import ItemListCarousel from '../components/ItemListCarousel';

type TItemListContainerProps = {
  items: Array<TItemDetailRequestEntry>;
  purchasable: boolean;
  selectable?: boolean;
  backgroundVisualContainer?: boolean;
  titleText: string;
  wrapItems: boolean;
  onSelectedItemsChanged?: (
    selectedItems: Array<TDetailEntry>,
    ownershipRecord: Record<string, boolean>
  ) => void;
  showDefaultBody?: boolean;
  onDefaultBodySelectChanged?: (useDefaultBody: boolean) => void;
  showCreatorName?: boolean;
  showPrice?: boolean;
  showItemType?: boolean;
};

export const ItemListContainer = ({
  items,
  purchasable,
  selectable,
  backgroundVisualContainer,
  titleText,
  wrapItems,
  onSelectedItemsChanged,
  showDefaultBody,
  onDefaultBodySelectChanged,
  showCreatorName,
  showPrice,
  showItemType
}: TItemListContainerProps): JSX.Element => {
  const [SystemFeedback, systemFeedbackService] = createSystemFeedback();
  let isSelectableBasedOnParams = selectable !== undefined ? selectable : false;
  if (purchasable) {
    isSelectableBasedOnParams = true;
  }
  return (
    <div
      className={`item-list${purchasable ? ' purchasable' : ''}${wrapItems ? ' wrap-items' : ''}`}>
      <ItemListCarousel
        items={items}
        selectable={isSelectableBasedOnParams}
        purchasable={purchasable}
        backgroundVisualContainer={backgroundVisualContainer || false}
        titleText={titleText}
        onSelectedItemsChanged={onSelectedItemsChanged}
        systemFeedbackService={systemFeedbackService}
        showDefaultBody={showDefaultBody}
        onDefaultBodySelectChanged={onDefaultBodySelectChanged}
        showCreatorName={showCreatorName !== undefined ? showCreatorName : true}
        showPrice={showPrice !== undefined ? showPrice : true}
        showItemType={showItemType !== undefined ? showItemType : false}
      />
      <SystemFeedback />
    </div>
  );
};

export default ItemListContainer;
